import React from "react";
import "./Testimonials.css";

const testimonials = [
  {
    name: "Fernando López",
    testimonial: (
      <React.Fragment>
        <p>
          En comparación a otros años, tuvimos una{" "}
          <strong>reducción importante en el tiempo de evaluación</strong>{" "}
          de personal.
          <br />
          <br/>
          La plataforma de
          {" "}
          <strong>
            Growthfactor nos ayuda a ser más objetivos y certeros 
          </strong>
          {" "}
          al momento de
            evaluar, y el{" "}
          <strong>equipo de soporte siempre tiene una respuesta rápida</strong>{" "}
          a las necesidades de nuestra organización.
        </p>
      </React.Fragment>
    ),
    position: "HR Manager	 ",
  },
  {
    name: "Verónica Barrera Cisneros",
    testimonial: (
      <React.Fragment>
        <p>Ahora tenemos una plataforma amigable que me{" "}
        <strong>permite evaluar objetivamente al personal</strong>. La atención
        y soporte hacen que sea un <strong>traje a la medida</strong>
        </p>
      </React.Fragment>
    ),
    position: "Gerente de Recursos Humanos",
  },
  {
    name: "Erik Mar",
    testimonial:
      <React.Fragment>
      <p>
      El mayor beneficio que he visto es que ahora tenemos los registros y controles en línea. Esto nos ayuda a estar <strong>alineados con los objetivos de la empresa</strong> y tener <strong>fácil acceso a la información</strong>.
      </p>
      </React.Fragment>,
    position: "Gerente de RH",
  },
];

function TestimonialCard({ name, testimonial }) {
  return (
    <div className="testimonial-card">
      <img
        src="/assets/icons/quote-symbol.svg"
        alt="Quote symbol"
        className="quote-symbol"
      />
      <div className="testimonial-content">
        <p className="testimonial-text">{testimonial}</p>
      </div>
      <h3 className="testimonial-author">- {name}</h3>
    </div>
  );
}

export function Testimonials() {
  return (
    <section id="testimoniales">
      <div className={`testimonial-container`}>
        {testimonials.map(({ name, testimonial }, index) => (
          <TestimonialCard name={name} testimonial={testimonial} />
        ))}
      </div>
    </section>
  );
}
