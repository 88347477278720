import React from 'react';

export function Card({ sectionId, title, description, imageHref, imageAlt,  showImageLeft = false}) {
	return(
		<section id={sectionId}>
		<div className={`flex-container ${showImageLeft && 'card__reverse'}`}>
			<div className='card__text'>
				<h2>{title}</h2>
				<p>{description}</p>
			</div>
		  <img className='cardImage' src={imageHref} alt={imageAlt}/>
		</div>
	  </section>
	)
}