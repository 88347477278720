import React from 'react';
import './App.css';
import { Card } from './Card';
import { Footer } from './Footer';
import { Header } from './Header';
import { NewsLetter } from './Newsletter';
import { Testimonials } from './Testimonials';


function Services() {
  return (
    <Card
    sectionId='servicios'
    title='Nuestros Servicios' 
    description='En Growthfactor, evaluamos objetivamente el desempeño de tu equipo, combinando
    indicadores cuantitativos y cualitativos, para identificar áreas de mejora y desarrollar
    planes de carrera personalizados que impulsen el crecimiento de sus colaboradores y el
    éxito de la empresa.
    Beneficios:
    Mejora significativa en la productividad.
    Reducción en la rotación de personal.
    Mayor compromiso y satisfacción laboral.
    Mejora en la toma de decisiones sobre la gestión del talento.' 
    imageHref= "/assets/images/Services.png"
   imageAlt="Services"

    />

  );
}

function About() {
  return (
    <Card
    sectionId='nosotros'
    title='Nosotros' 
    description='En un entorno empresarial en constante evolución y altamente competitivo, el verdadero
    capital de una organización reside en su talento. En Growthfactor, nos distinguimos como
    referentes en consultoría de Recursos Humanos, brindando soluciones personalizadas y
    estratégicas para impulsar el crecimiento de las empresas. Con más de 20 años de
    experiencia en productividad avanzada y desarrollo organizacional, somos el aliado
    ideal para que su organización alcance su máximo potencial.' 
    imageHref= "/assets/images/Nosotros.png"
    imageAlt="Nosotros"
    showImageLeft
    />

  );
}

function Mision() {
  return (
    <Card
    sectionId='mision'
    title='Misión' 
    description='Potenciar a las empresas a través de brindar una herramienta para la evaluación,
    medición y crecimiento de su capital humano, mediante una plataforma de vanguardia
    y servicios de consultoría excepcionales, para identificar objetivamente a los empleados
    más efectivos, cerrar las brechas entre sus KPIs, el rendimiento departamental y
    desarrollar al personal conforme a la visión estratégica de la organización'
    imageHref= "/assets/images/Mision.png"
   imageAlt="Mision"
    />
  );
}


function Choice() {
  return (
    <Card
    sectionId='Elegirnos'
    title='Por qué elegirnos'
    description='Brindamos un sistema de evaluación de desempeño diseñado específicamente para
    empresas que necesiten:
    
    Aumentar la productividad: Identificar y elimina los obstáculos que impiden que tu
    equipo de trabajo alcance su máximo potencial.
    Disminuir la rotación de personal: Crear un ambiente de trabajo positivo y motivador que
    impulse la retención de talento.
    Alcanzar los objetivos estratégicos: Alinear el desempeño individual con las metas de la
    organización'
    imageHref= "/assets/images/Elegirnos.png"
    imageAlt="Elegirnos"
    showImageLeft
    />

  );
}

function App() {
  return (
    <div className='background-image'>
      <Header />
      <main>
        <Services />
        <About />
        <Mision/>
        <Choice/>
        <NewsLetter/>
        <Testimonials/>
      </main>
      <Footer />
    </div>
  );
}

export default App;
