import React from 'react';

export function Footer() {
  return (
    <footer>
      
      <div className="social-icons">
        <a href= "https://www.linkedin.com/company/growth-factor-mexico/"> <img src="/icons/linkedin.png" alt="linkedIn" /> 
        </a>
        <a href= "mailto:contacto@growthfactor.dev " ><img src="/icons/mail.png"  alt="mail" />
        </a>
        < a href= "https://wa.me/5530320458 "><img src="/icons/whatsapp.png" alt="whatsapp" />
        </a>
      </div>
      <div className="container">
        <p>Derechos de Autor &copy; {new Date().getFullYear()} Growthfactor.</p>
      </div>
    </footer>
  );
}
