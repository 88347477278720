import React, { useState } from "react";
import "./Newsletter.css";

export function NewsLetter() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [findUs, setFindUs] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('https://api.growthfactor.dev/marketing/beta/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name:fullName, email, company, findUs }),
    });

    if (response.ok) {
      setIsSubmitted(true);
    }
  };

  return (
    <section id="newsletter">
      <div className="newsletter-container">
        <p>
          !Suscribete a nuestro newsletter para recibir gratuitamente artículos,
          investigaciones, acceso a webinars y más!
        </p>
        <div className={`form-container`}>
          {isSubmitted ? (
            <div>!Muchas gracias por suscribirte!</div>
          ) : (
            <form onSubmit={handleSubmit}>
              <label>
                Nombre Completo:<span className="required">*</span>
                <input type="text" name="fullName" required onChange={e => setFullName(e.target.value)} />            
              </label>
              <label>
                Correo electrónico:<span className="required">*</span>
                <input type="email" name="email" required onChange={e => setEmail(e.target.value)} />            
              </label>
              <label>
                Compañía:
                <input type="text" name="company" onChange={e => setCompany(e.target.value)} />            
              </label>
              <label>
                ¿Cómo nos encontraste?:
                <input type="text" name="findUs" onChange={e => setFindUs(e.target.value)} />
              </label>
              <input type="submit" value="Submit" />
            </form>
          )}
        </div>
      </div>
    </section>
  );
}