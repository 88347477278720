import React from "react";
import './Header.css'

export function Header() {
	return (
	  <header className="sticky-header">
		<div className="header__container">
		  <img src="/assets/images/full-logo-negativo.png" alt="Growthfactoryo"/>
		  <nav>
			<ul>
			  <li><a href="#servicios">Servicios</a></li>
			  <li><a href="#nosotros">Nosotros</a></li>
			  <li><a href="#newsletter">Contacto</a></li>
			  <li><a href="#testimoniales">Testimoniales</a></li>
			</ul>
		  </nav>
		</div>
	  </header>
	);
  }